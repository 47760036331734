import React from 'react';
import NavBar from './NavBar';

function App() {
  return (
    <div>
      <NavBar /> 
    </div>
  );
}

export default App;
